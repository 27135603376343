<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      
    }
  },
  created() {
	  
  },
  methods: {

  }
}
</script>

<style lang="scss">
@import "assets/styles/common";
</style>
